.CleeQSticky{
        position: sticky;
        position: -webkit-sticky;
        top: 0;
        z-index: 99999;
        background-color: white;
}

.NavBarContainer{
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0px;
        /* padding: 20px 50px 50px 10px; */
        /* border-bottom: 2px solid rgb(252, 245, 245); */
        gap: 2rem;
        /* background-color: red; */
        
        

}

.SignUpAndLoginContianer{
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 2rem;

}

.CleeQ24__Loog{
        width: 110px;
        height: 30px;
      }
.NavBarWrapper{
        display: flex;
        align-items: center;
        gap: 1rem;
        height: 80px;
}
.NavList1{
        display: flex;
        align-items: center;
        gap: 1.9rem;
        /* margin-left: 50px; */
       

}
.NavList{
        
        font-family: 'Urbanist', sans-serif;
        font-size: 13px !important;
        font-weight: 600;
        letter-spacing: 0.5px;
        color: #183153;

}
.NavList:hover{
       
        
        font-family: 'Urbanist', sans-serif;
        font-size: 13px !important;
        font-weight: 600;
        letter-spacing: 0.5px;
        color: gray;

}

.DrawerList{
        display: flex;
        flex-direction: column;
        gap: 3rem;
        margin-bottom: 30px;
}


.ClickLink{
        text-decoration: none;
        color: gray;
        cursor: pointer;
        font-size: 15px !important;
        position: relative;
        text-decoration: none;
        padding: 10px 0px;
        font-family: 'Urbanist', sans-serif;
        font-weight: 400;
       
      
      }
      
      .ClickLink:hover{
        text-decoration: none;
        color:  #F7AD27;
        cursor: pointer;
        font-size: 15px !important;
        font-family: 'Urbanist', sans-serif;
        font-weight: 400;
      
      }
      
      
      
      .ClickLink::before {
        content: '';
        position: absolute;
        width: 100%;
        height: 4px;
        border-radius: 4px;
        background-color:  #F7AD27;
        bottom: 0;
        left: 0;
        transform-origin: right;
        transform: scaleX(0);
        transition: transform .3s ease-in-out;
        
      }
      
      .ClickLink:hover::before {
        transform-origin: left;
        transform: scaleX(1);
      }

     
.SignIn-LogIn-Btn{
        background: none;
        /* border: 2px solid #F7AD27 ; */
       
        background-color: #F7AD27;
        color: black;
        padding: 10px 25px;
        border-radius: 50px;
        letter-spacing: 0.5px !important;
        font-family: 'Urbanist', sans-serif;
        font-weight: 600;
        font-size: 13px;
        border: none;
      }

      .SignIn-LogIn-Btn:hover{
        background: none;
        /* border: 2px solid #fff9e4 ; */
        background-color: #fff9e4;
        color: black;
        padding: 10px 25px;
        border-radius: 50px;
        letter-spacing: 0.5px !important;
        font-family: 'Urbanist', sans-serif;
        font-weight: 600;
        font-size: 13px;
        border: none;
      }

      .CleeQ__Link{
        text-decoration: none;
        font-family: 'Urbanist', sans-serif;
        font-weight: 600;
        font-size: 15px;
        color: #183153;
        letter-spacing: 0.5px;
      }
      .CleeQ__Link:hover{
        text-decoration: none;
        font-family: 'Urbanist', sans-serif;
        font-weight: 600;
        font-size: 15px;
        color: #183153;
        letter-spacing: 0.5px;
      }
