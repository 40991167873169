
.CleeQBody {
  background-color: black; /* Sets the background to black */
  color: white; /* Ensures text is readable against the black background */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 100vh; /* Full viewport height */
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
.JoinTheCleeQHeading{
  font-family: 'Urbanist', sans-serif; 
  /* font-family: 'Comforter', cursive; */
  /* font-family: 'Comfortaa', cursive; */
  font-size: 100px;
  font-weight: 400;
  text-align: center;
  
}

.JoinTheCleeQSub{
  font-family: 'Urbanist', sans-serif; 
  font-size: 20px;
  font-weight: 400;
  text-align: center;
  letter-spacing: 1px;
  
}
.PacCont{
  
  padding: 0px 160px 0px 160px;

 
}

.JoinTheCleeQTag{
color: #F7AD27;
vertical-align: top; /* Align trademark properly */
}
.JoinTheCleeQTag2 {
  font-size: 1.5rem; /* Slightly smaller size for the trademark */
  color: #f7ad27; /* Optional: distinct color for the brand name */
  margin-left: 5px; /* Space between text and trademark */
  vertical-align: top; /* Align trademark properly */
}
.centered-container {
display: flex;
justify-content: center;
align-items: center;
height: 100vh; /* Full viewport height */
width: 100%; /* Full width */
text-align: center;
box-sizing: border-box; /* Include padding and border in width/height calculations */
}

.centered-container > * {
max-width: 90%; /* Responsive sizing */
padding: 20px; /* Add spacing around the content */
}