
/* On screens that are 992px wide or less, the background color is blue */
@media screen and (max-width: 1400px) {
  .CleeQBody2 {
    background-color: yellow;
    color: green;
  }
  .WriteUpWrapper{
    padding: 0px 0px 0px 0px;
    z-index: 999;

}
  .CleeQLanding{
    margin-top: 50px;
}
.WorryLap{
  margin-top: 80px;
}
.WorryCont{
  padding: 0px 0px 0px 0px;
}
.REALCover{
  display: block;
}
.CleeQLanding{
  margin-top: 200px;
}


.Friends505{
       
  display: block;
}

.DesinCover{
  display: none;

 }
 .Support101{
  display: block;

 }
 .PaccDex01{
  margin-top: 150px;

}

.PaccDex0103{
  margin-top: 50px;

}

.PacCont{
        
  padding: 0px 150px 0px 150px;
 
}
/* NEW CSS */
.PackageWrapper{
  padding: 0px 120px 0px 120px;
  
  display: flex;
  align-items: center;
  justify-content: space-between;


}


.PackageImg{
  width: 100%;
  max-width: 100%;
  
  height: 350px;
  border-radius: 10px 0px 0px 10px;
  

}

.PackageWriteUp{
  max-width: 100%;
  width: 100%;
 
  height: 350px;
  border-radius: 0px 10px 10px 0px;
  padding: 60px;

}

/* .PackageWrapper02{
  padding: 0px 120px 0px 120px;
  display: flex;
  align-items: center;
  justify-content: space-between;


} */


/* 
.PackImg02{
  width: 100%;
  max-width: 100%;
  height: 350px;
  border-radius: 0px 10px 10px 0px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);


  

}



.PackageImg02{
  width: 100%;
  max-width: 100%;
  height: 350px;
  
  

} */


}


/* On screens that are 992px wide or less, the background color is blue */
@media screen and (max-width: 992px) {
  .CleeQBody2 {
    background-color: blue;
    color: green;
  }
  .WriteUpWrapper{
    padding: 0px 0px 0px 0px;
    z-index: 999;

}
  .CleeQLanding{
    margin-top: 50px;
}
.WorryLap{
  margin-top: 80px;
}
.WorryCont{
  padding: 0px 0px 0px 0px;
}
.REALCover{
  display: none;
}




.SignUpAndLoginTray{
  display: block;
 }

 .NavBarListDetails{
  display: none;

 }

 .SignUpAndLoginContianer22{
  display: none;

 }
 .PaccDex01{
  margin-top: 50px;

}

.PaccDex0103{
  margin-top: 50px;

}
.PacCont{
  
  padding: 0px 0px 0px 0px;
  
}
.ImgConTT{
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 5rem;
}
.WriteUpC{
  width: 100%;
 
  

}
.ImgC{
  display: none;
}
 
.CalCont{
        
  padding: 0px 20px 0px 20px;
 
}


/* NEW CSS */

.PackageWrapper{
  padding: 0px 0px 0px 0px;
 
  display: flex;
  flex-direction: column;
  /* align-items: center;
  justify-content: space-between; */


}

.PackageImg{
  width: 100%;
  max-width: 100%;
  
  height: 350px;
  border-radius: 10px 10px 0px 0px;
  

}

.PackageWriteUp{
  max-width: 100%;
  width: 100%;
 
  /* height: 400px; */
  border-radius: 0px 0px 10px 10px;
  padding: 40px 20px;

}


.PackageWriteUp02{
  max-width: 100%;
  width: 100%;
  background-color: #F8F8F8;
  height: 350px;
  
  border-radius: 0px 0px 10px 10px;
  padding: 40px 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);



}
 .PackImg02{
  width: 100%;
  height: 350px;
  border-radius: 10px 10px 0px 0px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);


  

}
 .PackageImg02{
  width: 100%;
  max-width: 100%;
  height: 350px;
  
  

}

.PackageWrapper02{
  padding: 0px 0px 0px 0px;
  display: flex;
  flex-direction: column-reverse;
  


}
.PacCont43{
 
  padding: 0px 0px 0px 0px;
 
}

.LandingWrapper{
  padding: 0px 0px 0px 0px;
  display: flex;
  
  flex-direction: column;


}

.LandingContainer{
  max-width: 100%;
  width: 100%;
  background-color: #F8F8F8;
  height: 650px;
  border-radius: 10px 10px 0px 0px;
  padding: 40px 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  /* box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
*/


}

.LandingCoverUp{
  display: flex;
  flex-direction: column;
  gap: 1rem;

}

.LandingHedings1{
        
  font-family: 'Urbanist', sans-serif; 
  font-size: 40px !important;
  font-weight: 800;
  letter-spacing: 0.5px;
  color: #183153;
 
    
}

.LaNdBank{
  font-family: 'Urbanist', sans-serif; 
  position: relative;
  font-size: 40px !important;
  font-weight: 800;
  letter-spacing: 0.5px;
  color: #F7AD27;
 
    
}
/* .LandingImg{
  width: 100%;
  height: 650px;
  border-radius: 0px 0px 10px 10px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
} */



.TrustHeading{
  font-family: 'Urbanist', sans-serif;
  font-size: 25px;
  font-weight: 600;
  letter-spacing: 0.5px;
  color: #183153;
  

}

.PackageWrapSub09{
  font-family: 'Urbanist', sans-serif;
  font-size: 25px;
  font-weight: 600;
  letter-spacing: 0.5px;
  color: #F7AD27;

}
}

/* On screens that are 600px wide or less, the background color is olive */
@media screen and (max-width: 600px) {
  .CleeQBody2 {
    background-color: olive;
    color: white;
  }
  .CleeQLanding{
    margin-top: 50px;
}
  .LandingHedings1{
    position: relative;
    font-size: 45px !important;
    font-weight: 800;
    letter-spacing: 0.5px;
    color: #183153;
    font-family: 'Urbanist', sans-serif; 
}


  .WriteUpWrapper{
    padding: 0px 0px 0px 0px;
    z-index: 999;

}

  .CleeQLanding{
    margin-top: 20px;
}
.boll{
  /* background-color: red; */
  /* background: rgb(2,0,36);
background: linear-gradient(90deg, rgba(2,0,36,1) 0%, rgba(190,200,162,1) 35%, rgba(0,212,255,1) 100%); */
  background: #F7AD27;  /* fallback for old browsers */
  background: -webkit-linear-gradient(to right, #ffffff, #F7AD27);  /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(to right, #ffffff, #F7AD27); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  width: 150px;
  height: 150px;
  position: absolute;
  margin-top: -10px;
  margin-left: -50px;
  border-radius: 50%;
  z-index: -999;

  
  
}
.WorryLap{
  margin-top: 80px;
}
.WorryCont{
  padding: 0px 0px 0px 0px;
}
.REALCover{
  display: none;
}



.PacLanding{
  margin-top: 0px;

}

.SignUpAndLoginTray{
  display: block;
 }

 .NavBarListDetails{
  display: none;

 }

 .SignUpAndLoginContianer22{
  display: none;

 }


 .PaccDex01{
  margin-top: 50px;

}
.PaccDex0103{
  margin-top: 50px;

}
.PacCont{
  
  padding: 0px 0px 0px 0px;
 
}
.ImgConTT{
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 5rem;
}
.WriteUpC{
  width: 100%;

  padding: 0px 20px;

}
.ImgC{
  display: none;
}
.CalCont{
        
  padding: 0px 20px 0px 20px;
 
}


/* NEW CSS */

.PackageWrapper{
  padding: 0px 0px 0px 0px;
  
  display: flex;
  flex-direction: column;
  /* align-items: center;
  justify-content: space-between; */


}

.PackageImg{
  width: 100%;
  max-width: 100%;
  
  height: 350px;
  border-radius: 10px 10px 0px 0px;
  

}

.PackageWriteUp{
  max-width: 100%;
  width: 100%;
 
  /* height: 400px; */
  border-radius: 0px 0px 10px 10px;
  padding: 40px 20px;

}




 .PackageWriteUp02{
  max-width: 100%;
  width: 100%;
  background-color: #F8F8F8;
  height: 350px;
  
  border-radius: 0px 0px 10px 10px;
  padding: 40px 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);



}
 .PackImg02{
  width: 100%;
  height: 350px;
  border-radius: 10px 10px 0px 0px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);


  

}
 .PackageImg02{
  width: 100%;
  max-width: 100%;
  height: 350px;
  
  

}

.PackageWrapper02{
  padding: 0px 0px 0px 0px;
  display: flex;
  flex-direction: column-reverse;
  


}
.PacCont43{
 
  padding: 0px 0px 0px 0px;
 
}

.LandingWrapper{
  padding: 0px 0px 0px 0px;
  display: flex;

  flex-direction: column;


}

.LandingContainer{
  max-width: 100%;
  width: 100%;
  background-color: #F8F8F8;
  height: 480px;
  border-radius: 10px 10px 0px 0px;
  padding: 0px 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  /* box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
*/
}

.NavBarContainer{
  margin-top: 20px;

}

.LandingCoverUp{
  display: flex;
  flex-direction: column;
  gap: 1rem;

}

.LandingHedings1{
        

  font-size: 40px !important;
  font-weight: 800;
  letter-spacing: 0.5px;
  color: #183153;
  font-family: 'Urbanist', sans-serif; 
}

.LaNdBank{
  font-family: 'Urbanist', sans-serif; 
  position: relative;
  font-size: 40px !important;
  font-weight: 800;
  letter-spacing: 0.5px;
  color: #F7AD27;
 
    
}
/* .LandingImg{
  width: 100%;
  height: 650px;
  border-radius: 0px 0px 10px 10px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
} */
.LandingImg {
  width: 100%;
  border-radius: 0px 0px 0px 0px;
  padding-bottom: 30px;
}


.TrustHeading{
  font-family: 'Urbanist', sans-serif;
  font-size: 25px;
  font-weight: 600;
  letter-spacing: 0.5px;
  color: #183153;
  

}

.PackageWrapSub09{
  font-family: 'Urbanist', sans-serif;
  font-size: 25px;
  font-weight: 600;
  letter-spacing: 0.5px;
  color: #F7AD27;

}


}